<template>
  <el-dialog
    :title="mainType.title"
    v-model="dialogOpen"
    :close-on-click-modal="false"
    :width="mainType.width"
    @close="closeDialog"
  >
    <div>
      <div class="flex-nowrap-space-around-other">
        <div :style="{ width: '500px' }">
          <el-form
            ref="upform"
            size="small"
            :model="upform"
            label-width="120px"
            :rules="rules"
          >
            <el-form-item label="接口类型：" prop="interfaceId">
              <el-select
                v-model="upform.interfaceId"
                placeholder="请选择查询的接口类型"
                style="width: 100%"
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in listArr"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类型：" prop="type" v-if="type === 'update'">
              <el-select
                v-model="upform.type"
                placeholder="请选择查询的类型"
                style="width: 100%"
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in typeOption"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="号码列表：" prop="number">
              <el-input
                v-model="upform.number"
                type="textarea"
                placeholder="请输入手机号(换行可输入多个)"
                :autosize="{ minRows: 6, maxRows: 8 }"
              />
              <div>一行一个号码</div>
            </el-form-item>
            <el-form-item label="">
              <el-button class="yes-green-bg" @click="theQuery('upform')"
                >{{mainType.buttonText}}</el-button
              >
            </el-form-item>
          </el-form>
          <div v-if="type === 'update'">
            <div
                v-for="(item, i) in dataList"
                :key="i"
            >
                <div>{{ item }}</div>
            </div>
          </div>
        </div>
        <div
          class="show-boxs"
          :style="{ width: '500px' }"
          v-if="type === 'search'"
        >
          <div v-if="dataList.length == 0">
            <el-empty description="暂无数据"></el-empty>
          </div>
          <div v-else>
            <div
              v-for="(item, i) in dataList"
              :key="i"
              class="flex-nowrap-space-around item-box-other"
            >
              <div>{{ item.number }}</div>
              <div>{{ item.statusName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { authConfigQuery, updateAuthConfig } from "@/api/web/virtualNumber";
import { message } from "ant-design-vue";
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      let num = 0,
        types = null,
        errorItem;
      if (value == null) {
        callback(new Error("请填写号码 (多个号码请换行输入)"));
      } else if (value.length == 0) {
        callback(new Error("请填写号码 (多个号码请换行输入)"));
      } else {
        let newArr = [];
        newArr = value.split(/[(\r\n)\r\n]+/);
        newArr.forEach((item, i) => {
          if (
            item.match(
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9]|0[0-9][0-9][0-9])\d{8}$/
            ) == null
          ) {
            // if(item.match(/^(1[3|4|5|6|7|8|9])\\d{9}$|^0\\d{2,3}-?\\d{7,8}$|^9\\d{9}/) == null){
            // callback(new Error('请输入正确的号码 (请检查第'+(i+1)+"行)"))
            num++;
            types = i;
            errorItem = item;
          }
        });
        if (num != 0) {
          callback(
            new Error(
              "请输入正确的号码 (请检查第" +
                (types + 1) +
                "行：" +
                errorItem +
                ")"
            )
          );
        } else {
          callback();
        }
      }
    };
    return {
      dialogOpen: false,
      upform: {
        interfaceId: null,
        type: null,
        number: null,
      },
      listArr: [
        {
          name: "虚拟号AXB",
          id: 1838,
        },
        {
          name: "虚拟号外显",
          id: 918,
        },
      ],
      typeOption: [
        {
          name: "双不透",
          id: '00',
        },
        {
          name: "单透",
          id: '10',
        },
      ],
      dataList: [],
      rules: {
        interfaceId: [
          {
            required: true,
            message: "请选择查询的接口类型",
            trigger: "change",
          },
        ],
        number: [{ required: true, validator: checkNumber, trigger: "blur" }],
      },
    };
  },
  computed: {
    mainType() {
      switch (this.type) {
        case "search":
          return {
            title: "查询",
            width: "60%",
            buttonText: '查 询',
            requestParamsKey: [
                'interfaceId',
                'numberList'
            ],
            request: authConfigQuery
          };
        case "update":
          return {
            title: "更新",
            width: "30%",
            buttonText: '确 定',
            requestParamsKey: [
                'interfaceId',
                'type',
                'numberList'
            ],
            request: updateAuthConfig
          };
        default:
          return {
            title: "",
            width: "0%",
            requestParamsKey: [],
            request: null
          };
      }
    },
  },
  methods: {
    theQuery(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let upArr = [];
          let arrLest = this.upform.number.split(/[(\r\n)\r\n]+/);
          this.unique(arrLest).forEach((item, i) => {
            upArr.push(item.trim());
          });
          const paramsData = {
            interfaceId: this.upform.interfaceId,
            type: this.upform.type,
            numberList: upArr
          }
          const params = this.mainType.requestParamsKey.reduce((pre, key) => {
            Object.assign(pre, {
                [key]: paramsData[key]
            })
            return pre;
          }, {})
          this.mainType.request(params).then((res) => {
            if (res.code == 200) {
              this.dataList = res.data;
              if (res.data.length == 0 && this.type == 'update') {
                this.dialogOpen = false;
                message.success('操作成功')
              }
            } else {
                message.error(res.message)
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    unique(arr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a) && res.set(a, 1));
    },
    closeDialog() {
      this.dataList = [];
      this.$refs.upform.resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
</style>